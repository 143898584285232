<template>
    <div>
        
        <h1 class="text-2xl font-bold mb-6">Company Onboarding</h1>

        <tabs :tabs="tabs" v-model="tab" class="mb-10" :clickable="false" />

        <component :is="tab" @success="next" @skip="success" />

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="modal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Success!
            </div>
            <div class="text-xs mb-10">
                Company information successfully updated.
            </div>

            <router-link :to="{ name: 'dashboard' }" class="button bg-blue-500 hover:bg-blue-600 text-white">
                Go to Dashboard
            </router-link>
            
        </modal>

        <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" @close="done" ref="AllSuccessModal">

            <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

            <div class="text-lg font-bold mb-4">
                Onboarding Complete.
            </div>
            <div class="text-xs mb-10">
                You successfully completed the onboarding process for your company
            </div>

            <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click="done">
                Go To Dashboard
            </button>
            
        </modal>

    </div>
</template>

<script>
    export default {
        components: {
            profile: () => import('./Profile.vue'),
            groups: () => import('./Groups.vue'),
            staff: () => import('./Staff.vue'),
        },
        data() {
            return {
                checkmark: require('@/assets/checkmark-base.svg'),
                tab: 'profile',
                tabs: [
                    { name: 'profile', title: 'Company Profile' },
                    { name: 'groups', title: 'Add Department' },
                    { name: 'staff', title: 'Add Employees' },
                ],
            }
        },
        computed: {
            errorMessage() {
                if (this.form.error.toString().match(/Error: Network Error/i)) {
                    return 'Please check your internet connection';
                }

                const errors = this.form.error?.response?.data?.errors;
                if (errors && Object.keys(errors).length) {
                    return 'Please check the form for incorrect or missing data';
                }

                return null;
            },
        },
        mounted() {
            if (this.user?.company?.profile) {
                this.tab = 'staff';
            }
        },
        methods: {
            next() {
                switch (this.tab) {
                    case 'groups':
                        this.tab = 'staff';
                        break;
                    case 'staff':
                        this.success();
                        break;
                    case 'profile':
                    default:
                        this.tab = 'groups';
                        break;
                }
            },
            done() {
                this.$router.push({ name: 'dashboard', params: { accountType: this.ENV_VUE_APP_CORPORATE_PATH } });
            },
            finish() {
                this.success();
            },
            success() {
                this.$refs.AllSuccessModal.open();
            }
        }
    }
</script>
<template>

    <div class="flex">
        <div class="w-full sm:w-2/3 md:w-3/5 lg:w-7/10 xl:w-1/2 mx-auto">
            <component :is="appMode"></component>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            super: require('./Super.vue').default,
            staff: require('./Staff.vue').default,
            company: require('./company/Index.vue').default,
        }
    }
</script>